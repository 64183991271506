import { LdsButton, LdsIcon, LdsLink, LdsTable, useToastContext } from "@elilillyco/ux-lds-react";
import '../../assets/components/response-matrix/responseMatrixSummary.scss';
import { useEffect, useState } from "react";
import LdsSortableTable from "../../data/utils/SortableTable";
import {downloadRmsFile} from "../../store/responseMatrix/rmsStore"
import infoToastConfig from "../../data/utils/infoToastConfig.js";
import { useDispatch } from "react-redux";

export default function RmSummaryTable({ rmsTableList, tableApiStatus }) {
  //table data
  const [tableRowData, setTableRowData] = useState(rmsTableList);
  
  const { addToast } = useToastContext();

  const dispatch = useDispatch();

  useEffect(() => {
    setTableRowData(rmsTableList);
  }, [rmsTableList]);


  async function downloadFile(file_name) {
    addToast(
      infoToastConfig({
        toastMessage: "Preparing To Download The File..",
        variant: "informative",
      })
    );
    const { payload } = await dispatch(downloadRmsFile(file_name));
    const url = payload.url;
    if (url !== "") {
      window.location.href = url;
      addToast(
        infoToastConfig({
          toastMessage: "Downloaded Successfully.",
          variant: "success",
        })
      );
    } else {
      addToast(
        infoToastConfig({
          toastMessage: "Source File Doesn't Exist.",
          variant: "error",
        })
      );
    }
  }
  
  function sortedRowData(sortedData) {
    setTableRowData(sortedData);
  }
  return (
    <div>
      <LdsTable id="rmsTable" >
        <LdsSortableTable
          headAlign={'align-left b'}
          header={['Response Matrix', 'Sent date', 'Received date', 'Count' , 'Action']}
          rowData={rmsTableList}
          rowKeyName={['filename', 'sent_date', 'received_date', 'rm_count', 'rm_action']}
          sortedRowData={sortedRowData}
        />
        <tbody>
          {
            tableApiStatus
              ?
              tableRowData.map((data, index) => (
                <tr role="row" key={index} >
                  <td role="cell" className="align-left rmsName">
                    <p className="rmsNamecol">
                      {data.filename}
                    </p>
                  </td>
                  <td role="cell" className="center">
                    {data.sent_date === 'None' ? '' : data.sent_date}
                  </td>
                  <td role="cell" className="center">
                    {data.received_date === 'None' ? '' : data.received_date}
                  </td>
                  <td role="cell" className="center">
                    {data.rm_count}
                  </td>
                  <td role="cell" className="center">
                  <LdsButton
                                linkButton={true}
                                className="downloadIcon"
                                onClick={() => {
                                  downloadFile(data.filename);
                                }}>
                                <div>
                                  <LdsIcon
                                    name="DownloadSimple"
                                    inline
                                    decorative
                                    label="ResponseMatrixFile"
                                  />
                                </div>
                              </LdsButton>
                  </td>
                </tr>
              ))
              :
              <tr role="row" >
                <td className="rmsApiStatus">Unable to load data from API.</td>
              </tr>
          }
        </tbody>
      </LdsTable>
    </div>
  );
}
