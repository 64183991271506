import { LdsButton, LdsIcon, LdsLoadingSpinner, LdsPagination, LdsTable, LdsTextField, LdsTile, useToastContext, LdsLink } from '@elilillyco/ux-lds-react'
import React, { useEffect, useState } from 'react'
import HeaderNav from '../HeaderNav'
import { useDispatch, useSelector } from 'react-redux';
import { editException, fetchException } from '../../store/Components/OMMS/exceptionReporting';

const columns = [
    'SupplierName',
    'Platform',
    'MetricLabel',
];

const ExceptionReporting = () => {
    const dispatch = useDispatch();
    const [apiRes, setApiRes] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [inputColumn, setInputColumn] = useState(null);
    const [searchValue, setSearchValue] = useState({
        supplierName: '',
        Platform: '',
        MetricLabel: '',
    })
    const [inputColumns, setInputColumns] = useState({});
    const [totalPage, setTotalPage] = useState()
    const [statusSearchFld1, setIsON1] = React.useState(false);
    const [statusSearchFld2, setIsON2] = React.useState(false);
    const [metricLabel, setMetricLabel] = useState('')
    const [supplierName, setSupplierName] = useState('')
    const [statusSearchFld3, setIsON3] = React.useState(false);
    const [platform, setPlatform] = useState('')

    const deploy = {
        "edit_values": {
            "exception_id": selectedRows,
            "is_deployed": true
        }
    }
    const reviewNotNeed = {
        "edit_values": {
            "exception_id": selectedRows,
            "active_flag": false
        }
    }

    const apiPayload = {
        active_flag: true,
        supplier_name: supplierName,
        metric_label: metricLabel,
        platform: platform,
        pageSize: 10,
        pageNumber: currentPage,
        status: '',
        contact_type: '',
    };
    const loader = useSelector(({ exceptionSlice }) => exceptionSlice.loader);
    const totalPages = useSelector(({ exceptionSlice }) => exceptionSlice.totalPages)
    const { addToast } = useToastContext();

    useEffect(() => {
        dispatch(fetchException(apiPayload)).then((d) => (setApiRes(d?.payload?.data), setTotalPage(d?.payload?.numberOfPages)));
    }, [currentPage]);

    const handleEdit = (type) => {
        const action = type === 'rnn' ? reviewNotNeed : deploy;
        setApiRes()
        dispatch(editException(action))
            .then(() => {
                dispatch(fetchException(apiPayload))
                    .then((d) => {
                        setApiRes(d?.payload?.data);
                    });
                addToast({
                    toastMessage: "Your changes have been saved successfully ",
                    actionText: "",
                    variant: "success",
                    position: "top",
                    align: "center",
                    dismissible: true,
                    light: false,
                    timeout: 8000,
                    inline: false,
                    autoDismiss: true,
                    zIndex: 11
                })
                return;
            });
    }

    const handleRowSelect = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(prevSelectedRows => prevSelectedRows.filter(id => id !== rowId));
        } else {
            setSelectedRows(prevSelectedRows => [...prevSelectedRows, rowId]);
        }
    }
    useEffect(() => {
        // Check if all rows are selected after the state update
        if (apiRes?.length > 0) {
            setSelectAll(apiRes?.every(row => selectedRows.includes(row.omms_exception_reporting_id)));
        } else {
            setSelectAll(false);
        }
    }, [selectedRows, apiRes]);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(apiRes?.map(row => row.omms_exception_reporting_id));
        }
        // Toggle the selectAll state
        setSelectAll(!selectAll);
    }

    function archive() {
        window.location.href = '/omms/ExceptionArchive';
    }

    const handleHeaderClick = (columnName) => {
        setInputColumns(prevState => ({ ...prevState, [columnName]: true }));
    }

    const keyMapping = {
        'SupplierName': 'SupplierName',
        'MetricLabel': 'MetricLabel',
        'Platform': 'Platform',
    };


    const handleInputChange = (columnName, value) => {
        value = value.replace(/,\s+/g, ',');
        const mappedKey = keyMapping[columnName] || columnName;
        setSearchValue(prevState => ({
            ...prevState,
            [mappedKey]: value
        }));
        if (value.trim() !== '') {
            setInputColumns(prevState => ({ ...prevState, [columnName]: true }));
        } else {
            // If input is empty, allow the column to close
            const newInputColumns = { ...inputColumns };
            delete newInputColumns[columnName];
            setInputColumns(newInputColumns);
        }
    }


    const handleSearch = () => {
        setApiRes()
        dispatch(fetchException(apiPayload)).then((d) => (setApiRes(d?.payload?.data)));
    }

    const handleClear = () => {
        setIsON1(false)
        setIsON2(false)
        setIsON3(false)
        setSupplierName('')
        setPlatform('')
        setMetricLabel('')
        setSearchValue({
            supplierName: '',
            Platform: '',
            MetricLabel: '',
        })
        // Reset the inputColumns state
        setInputColumns({});

        const clearPayload = {
            active_flag: true,
            supplier_name: '',
            metric_label: '',
            platform: '',
            pageSize: 10,
            pageNumber: currentPage,
            status: '',
            contact_type: '',
        }
        dispatch(fetchException(clearPayload)).then((d) => (setApiRes(d?.payload?.data)));
    }

    const firstPage = () => {
        setCurrentPage(1)
    }
    const lastPage = () => {
        setCurrentPage(totalPages)
    }


    return (
        <>
            <HeaderNav msg={"Exception Reporting"} />
            <div className="ms-3 mt-2 p-2">
                <LdsLink href="/omms">
                    <LdsIcon
                        description="CaretLeft"
                        label="search"
                        name="CaretLeft"
                        inline
                    />
                    Back to Overview
                </LdsLink>
            </div>
            <LdsTile className='overview-hero ' >
            <div className="d-flex ">
                    <div>
                    <span className='overview-text'>Exception <br/> Overview</span>
                    </div>
                    <div className='button-container-ov'>
                        <LdsButton onClick={() => handleEdit('deploy')} className='col buttons'>Deploy</LdsButton>
                        <LdsButton onClick={() => handleEdit('rnn')} className='col buttons'>Review Not Needed</LdsButton>
                        <LdsButton onClick={archive} className='col buttons'>View Archives</LdsButton>
                        <LdsButton onClick={() => handleClear()} className='col buttons'>Clear Search</LdsButton>
                    </div>
                </div>
            </LdsTile>

            {loader && <div className='container mt-4'><LdsLoadingSpinner size={50} animationSpeed={500} />

            </div>
            }

            {
                (apiRes?.length < 1 && !loader) &&
                <span>No Data available </span>
            }
            {
                (apiRes?.length >= 1 && !loader) &&
                <div>
                    <LdsTable className="container">
                        <thead >
                            <tr>
                                <th scope="col">
                                    <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                                </th>
                                <th scope="col" className="table-ends">
                                    <div class="attriDivCore">
                                        Supplier Name
                                        <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                                            <LdsIcon
                                                description="MagnifyingGlass"
                                                label="search"
                                                name="MagnifyingGlass"
                                                class="icon-size"
                                                style={{
                                                    height: '15px',
                                                    marginBottom: '-2rem',
                                                    maxWidth: '2.5rem',
                                                    minWidth: '2.5rem'
                                                }}
                                                onClick={() => { setIsON1(!statusSearchFld1) }}
                                            />
                                        </div>
                                    </div>
                                    {statusSearchFld1 &&
                                        <div style={{ width: '12rem' }}>
                                            <LdsTextField
                                                // className='text-fld-exception'
                                                id='orgName'
                                                value={supplierName}
                                                onChange={(e) => setSupplierName(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                                                onKeyDown={(e) => { if (e.key === "Enter") { handleSearch() } }}
                                                name='orgName' />
                                        </div>
                                    }
                                </th>
                                <th scope="col" className='table-ends'>
                                    <div class="attriDivCore">
                                        Platform
                                        <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                                            <LdsIcon
                                                description="MagnifyingGlass"
                                                label="search"
                                                name="MagnifyingGlass"
                                                class="icon-size"
                                                style={{
                                                    height: '15px',
                                                    marginBottom: '-2rem',
                                                    maxWidth: '2.5rem',
                                                    minWidth: '2.5rem'
                                                }}
                                                onClick={() => {
                                                    setIsON2(!statusSearchFld2);
                                                }}

                                            />
                                        </div>
                                    </div>
                                    {statusSearchFld2 &&
                                        <div style={{ width: '12rem' }}>
                                            <LdsTextField
                                                // className='text-fld'
                                                id='orgName'
                                                value={platform}
                                                onChange={(e) => setPlatform(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                                                onKeyDown={(e) => { if (e.key === "Enter") { handleSearch() } }}
                                                name='orgName' />
                                        </div>
                                    }
                                </th>
                                <th scope="col" className='table-ends'>
                                    <div class="attriDivCore">
                                        Metric Label
                                        <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                                            <LdsIcon
                                                description="MagnifyingGlass"
                                                label="search"
                                                name="MagnifyingGlass"
                                                class="icon-size"
                                                style={{
                                                    height: '15px',
                                                    marginBottom: '-2rem',
                                                    maxWidth: '2.5rem',
                                                    minWidth: '2.5rem'
                                                }}
                                                onClick={() => { setIsON3(!statusSearchFld3) }}
                                            />
                                        </div>
                                    </div>
                                    {statusSearchFld3 &&
                                        <div style={{ width: '12rem' }}>
                                            <LdsTextField
                                                // className='text-fld'
                                                id='orgName'
                                                value={metricLabel}
                                                onChange={(e) => setMetricLabel(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                                                onKeyDown={(e) => { if (e.key === "Enter") { handleSearch() } }}
                                                name='orgName' />
                                        </div>
                                    }
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {apiRes?.map((resp) =>
                                <tr role="row">
                                    <td role="rowheader" scope="row">
                                        <input type="checkbox" checked={selectedRows.includes(resp?.omms_exception_reporting_id)} onChange={() => handleRowSelect(resp.omms_exception_reporting_id)} />
                                    </td>
                                    <td className='ta-start' role="rowheader" scope="row">{resp?.supplierName}</td>
                                    <td className='ta-start' role="rowheader" scope="row">{resp?.platform}</td>
                                    <td className='ta-start' role="rowheader" scope="row">{resp?.metricLabel}</td>
                                </tr>
                            )}
                        </tbody>
                    </LdsTable>
                    {!loader &&
                        <div className="d-flex">
                            {/* <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => firstPage()}>First</LdsButton> */}

                            <LdsPagination
                                currentPage={currentPage}
                                onNextClick={() => setCurrentPage(currentPage + 1)}
                                onPrevClick={() => setCurrentPage(currentPage - 1)}
                                setCurrentPage={setCurrentPage}
                                useButtons={true}
                                pageRangeVisible={5}
                                totalPages={totalPages}
                            />
                            {/* <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => lastPage()}>Last</LdsButton> */}
                        </div>
                    }
                </div>
            }


        </>
    );
}

export default React.memo(ExceptionReporting);